import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2

const Web = () => {

    const fiveWindow = useRef()
    const fivePath1 = useRef()
    const fivePath2 = useRef()
    const fiveShadow = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
      tl.set(fivePath1.current, {scaleX:0, scaleY: 0, transformOrigin:"center"})
        .set(fivePath2.current, {scaleX:0, scaleY: 0, transformOrigin:"center"})
        .to(fiveWindow.current, 0.3, {scaleX:0.85, scaleY: 0.85, transformOrigin:"center"})
        .to(fiveShadow.current, 0.3, {scaleX:0.45, scaleY: 0.75, transformOrigin:"center"}, 0)

        .to(fiveWindow.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"center", ease:Elastic.easeOut})
        .to(fiveShadow.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"center", ease:Elastic.easeOut}, "-=0.6")
        .to(fivePath1.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"center", ease:Elastic.easeOut})
        .to(fivePath2.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"center", ease:Elastic.easeOut});
    }, [] )

    return (
        <div>
            
            <svg width="105px" height="100px">
                <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
                    <g transform="translate(-360.000000, -172.000000)">
                        <g transform="translate(362.000000, 174.000000)">
                            <g ref={fiveWindow}>
                                <rect stroke="#4836FF" strokeWidth="2" x="1" y="1" width="97" height="69" rx="3"></rect>
                                <path d="M1.5,12.5 L97.5109198,12.5" stroke="#4836FF" strokeWidth="2" strokeLinejoin="round"></path>
                                <circle fill="#4836FF" cx="6.5" cy="6.5" r="1.5"></circle>
                                <circle fill="#4836FF" cx="12.5" cy="6.5" r="1.5"></circle>
                                <circle fill="#4836FF" cx="18.5" cy="6.5" r="1.5"></circle>
                            </g>
                            <g transform="translate(25.000000, 25.000000)" stroke="#4836FF" strokeLinejoin="round" strokeWidth="2">
                                <circle ref={fivePath1} cx="26" cy="19" r="15"></circle>
                                <polygon ref={fivePath2} fill="#FFFFFF" strokeLinejoin="round" points="11 0 22 19 0 19"></polygon>
                            </g>
                            <ellipse ref={fiveShadow} fill="#E4E1FF" cx="49.5" cy="90" rx="28.5" ry="4"></ellipse>
                        </g>
                    </g>
                </g>
            </svg>

        </div>
    )
}

export default Web
