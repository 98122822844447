import React from 'react'
import styled from 'styled-components'

import device from '../../styles/device'

import VostokStars from '../vostokStars'

import ServicesHeroTitle from './servicesHeroTitle'
import CtaScrollDown from '../ctaScrollDown'




const ServicesHeroS = styled.div`
  background-color: #FFFFFF;
  height: 100vh;
  position: relative;
  display: flex;

  padding: 180px 8.3333% 80px;
  height: 100vh;
  box-sizing: border-box;

  @media ${device.tablet}{
      padding: 20px;
  }
`

const ServicesHeroContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 100%;

  max-width: 1440px;
  margin: 0 auto 80px;
  width: 100%;

  @media ${device.tablet}{
    flex-direction: column;
  }
`

const ServicesHero = ({heroTitle}) => (
  <ServicesHeroS>
    <ServicesHeroContainer>

      <VostokStars />

          <ServicesHeroTitle title={heroTitle} tag="Servicios"/>
        
      <CtaScrollDown target="#servicesContent"/>

    </ServicesHeroContainer>
  </ServicesHeroS>
);

export default ServicesHero;