import React, {useState} from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import styled from "styled-components"
import Img from "gatsby-image"

import device from "../../styles/device"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"

import { useSpring, animated } from 'react-spring'

//utils:
import useIntersect from '../../utils/useIntersect'




const ServiceSliderSection = styled.div``

const Container = styled.div`
  cursor: grab;
  background-color: ${colors.secondary};
  color: white;

  .slick-slider,
  .slick-list {
    overflow: visible;
  }
  
  .slick-track {
    display: flex;
  }

  .slick-slide {
    width: fit-content;
    height: auto;

    > div {
      display: flex;
      height: 100%;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    display: flex !important;
    position: absolute;
    
    padding: 0 8.33vw;
    width: 50vw;
    bottom: 140px;
    box-sizing: border-box;
    
    list-style: none;

    @media ${device.tablet} {
      width: 100vw;
      padding: 0 10px;
      bottom: calc(50vh + 20px);
    }

    @media ${device.phone} {
      width: 100vw;
      padding: 0 5px;

      bottom: calc(50vh + 20px);
      
      justify-content: space-between;
    }

    .slick-active > button {
      opacity: 1;
        ::before {
          opacity: 0;
          color: transparent;
        }
    }
  }

  .slick-dots li {
    width: initial;
    height: initial;
    margin: 0;
  }

  .slick-dots li button {
    width: initial;
    height: initial;
    
    padding: 20px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    color: white;
    opacity: 0.5;
    font-size: 16px;
    line-height: 29px;

    @media ${device.phone} {
      padding: 0 15px;
    }

    ::before {
      opacity: 0;
      color: transparent;
    }
  }
`

const Slide = styled.div`
  display: flex !important;
  outline: none;
  width: 100vw;
  font-family: ${fonts.poppins};
  
  @media ${device.tablet} {
    flex-direction: column;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 29px;

    @media ${device.tablet} {
      font-size: 14px;
      line-height: 25px;
    }
  }

  h2 {
    font-size: 23px;
    font-weight: bold;
    line-height: 26px;
    margin: 0 0 50px;

    @media ${device.phone} {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 29px;
    }
  }

`

const LeftContainer = styled.div`
  width: 50vw;
  box-sizing: border-box;
  padding: 140px 8.3333vw;
  margin-bottom: 140px;

  @media ${device.tablet} {
    width: 100vw;
    padding: 140px 30px;
    height: 50vh;
    margin-bottom: 0;
  }

  @media ${device.phone} {
    padding: 40px 20px 60px;
    height: inherit;
    margin-bottom: 20px;
  }
`

const SlideTitle = styled.h3`
  font-size: 40px;
  line-height: 56px;
  font-weight: bold;
  margin: 0 0 25px;

  @media ${device.phone} {
    font-size: 23px;
    line-height: 26px;
  }
`

const RightContainer = styled.div`
  width: 50vw;

  @media ${device.tablet} {
    width: 100vw;
    height: 50vh;
    margin-top: auto;
  }

  .gatsby-image-wrapper {
    height: 100%;
    div, img {
      height: 100%;
    }
  }

  div, img {
    width: 100%;
  }
`

const ServiceSlider = ({sliderTitle, slides}) => {

  //slick slider settings:
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const [show, setShow] = useState(false)

  //spring fade in animation:
  const thrs = 0.25
    const [ref, entry] = useIntersect({
      threshold: thrs
    });

  const springProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(200px)`
    },
    to: {
      opacity: show ? 1 : 0,
      transform: `translateY(${show ? 0 : 200}px)`
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  });

  const AnimatedContainer = animated(Container);

  
  return (
    <ServiceSliderSection ref={ref}>
      
      <AnimatedContainer style={springProps}>

        <Slider {...settings} className="carousel">
          {slides.map((slide, i) => (

            <Slide key={i}>

              <LeftContainer className="left">

                  <h2>{sliderTitle}</h2>
                  <SlideTitle dangerouslySetInnerHTML={{__html: slide.node.title}} />
                  <div dangerouslySetInnerHTML={{__html: slide.node.content}} />

              </LeftContainer>

              <RightContainer className="right">
                <Img fluid={slide.node.featured_media.localFile.childImageSharp.fluid}/>
              </RightContainer>

            </Slide>
          ))}
        
        </Slider>

      </AnimatedContainer>

    </ServiceSliderSection>
  )


};

export default ServiceSlider;