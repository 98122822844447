import React from 'react'
import Particles from 'react-particles-js'
import styled from 'styled-components'
import colors from '../../styles/colors'

const VostokConnectS = styled.div`
  position:absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: linear-gradient(to bottom, ${colors.primary} 0%, ${colors.primary} 5%, ${colors.secondary} 50%, ${colors.primary} 95%, ${colors.primary} 100%);
  background-size: 100% 2000%;
  animation: AnimationSky 15s ease infinite;

  @keyframes AnimationSky { 
    0%{background-position:0% 100%}
    100%{background-position:0% 0%}
  }
`

const VostokStarsContainer = () => {

  const ww = typeof window !== 'undefined' ? window.innerWidth : 100

  return(
    <VostokConnectS>
      <Particles
        className="vostokConnectContainer"
        params={{
            "particles": {
              "number": {
                "value": ww < 480 ? 5 : 10,
                "density": {
                  "enable": false,
                  "value_area": 0
                }
              },
              "color": {
                "value": "#ffffff"
              },
              "opacity": {
                "value": 1,
                "random": false
              },
              "size": {
                "value": 2,
                "random": false
              },
              "line_linked": {
                "enable": false,
              },
              "move": {
                "enable": true,
                "speed": 25,
                "direction": "bottom-left",
                "random": false,
                "straight": true,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                  "enable": false,
                  "rotateX": 600,
                  "rotateY": 1200
                }
              }
            },
            "retina_detect": true
          }} />
    </VostokConnectS>
  )
}

export default VostokStarsContainer