import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2

const DigitalBranding = () => {

    const threeWindow = useRef()
    const threePath1 = useRef()
    const threePath2 = useRef()
    const threePath3 = useRef()
    const threeShadow = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
        tl.set(threePath1.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .set(threePath2.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .set(threePath3.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .to(threeWindow.current, 0.3, {scaleX:0.85, scaleY: 0.85, transformOrigin:"center"})
        .to(threeShadow.current, 0.3, {scaleX:0.45, scaleY: 0.75, transformOrigin:"center"}, 0)

        .to(threeWindow.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"center", ease:Elastic.easeOut})
        .to(threeShadow.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"center", ease:Elastic.easeOut}, "-=0.6")
        .to(threePath1.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut})
        .to(threePath2.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"left", ease:Elastic.easeOut})
        .to(threePath3.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"left", ease:Elastic.easeOut});
    }, [] )

    return (
        <div>
            <svg width="91px" height="93px">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g ref={threeWindow} transform="translate(-790.000000, -175.000000)">
                        <g transform="translate(796.000000, 176.000000)" stroke="#4836FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                            <path d="M59.078042,58.4617483 C59.078042,50.9169231 54.1202797,45.5279021 46.1441958,45.5279021 L15.7901399,45.5279021 C6.84433566,45.5279021 -1.77635684e-14,38.3061538 -1.77635684e-14,29.7377622 C-1.77635684e-14,21.1688811 7.81454545,13.4625175 16.2752448,13.4625175 L45.571958,13.4625175 C52.7457343,13.4625175 59.0344755,8.36377622 59.0344755,0 C59.0344755,8.36377622 59.078042,58.4617483 59.078042,58.4617483 Z"></path>
                            <path d="M17.0463217,45.493049 C18.5241538,45.493049 19.5276503,46.6747273 19.5276503,47.9743776 L19.5276503,63.2838182 C19.5276503,65.4543077 20.6256224,69.6131888 24.9333147,69.6131888 C29.2414965,69.6131888 30.2675105,65.8978042 30.2675105,63.2094126 L30.2675105,47.9675245 C30.2675105,46.685007 31.0321259,45.5375944 32.8207972,45.5375944"></path>
                            <path d="M59.0536643,38.2832937 C64.0535245,38.2832937 68.1061818,34.2306364 68.1061818,29.2307762 C68.1061818,24.2314056 64.0535245,20.1782587 59.0536643,20.1782587"></path>
                            <path d="M30.4686993,53.0967133 C34.3226154,53.0967133 37.4466713,49.9721678 37.4466713,46.1182517"></path>
                            <path d="M40.2146573,13.4626154 L40.2146573,27.9594685"></path>
                        </g>
                        <path ref={threePath1} d="M875.527049,192.162804 L869.014601,197.814203" id="Stroke-15" stroke="#4836FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path ref={threePath2} d="M879.599923,205.230923 L870.977685,205.230923" id="Stroke-11" stroke="#4836FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path ref={threePath3} d="M875.457734,219.150203 L869.083818,213.34314" id="Stroke-13" stroke="#4836FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <ellipse ref={threeShadow} fill="#E4E1FF" cx="825.5" cy="264" rx="28.5" ry="4"></ellipse>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default DigitalBranding