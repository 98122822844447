import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2

const Metodologia = () => {

    const twoWindow = useRef()
    const twoCircle1 = useRef()
    const twoCircle2 = useRef()
    const twoCircle3 = useRef()
    const twoPath1 = useRef()
    const twoPath2 = useRef()
    const twoPath3 = useRef()
    const twoPath4 = useRef()
    const twoPath5 = useRef()
    const twoPath6 = useRef()
    const twoCheck = useRef()
    const twoShadow = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
        tl.set(twoCircle1.current, {scaleX:0, scaleY: 0, transformOrigin:"center"})
        .set(twoCircle2.current, {scaleX:0, scaleY: 0, transformOrigin:"center"})
        .set(twoCircle3.current, {scaleX:0, scaleY: 0, transformOrigin:"center"})
        .set(twoPath1.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .set(twoPath2.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .set(twoPath3.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .set(twoPath4.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .set(twoPath5.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .set(twoPath6.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .set(twoCheck.current, {scaleX:0, scaleY: 0, transformOrigin:"center"})
        .to(twoWindow.current, 0.3, {scaleX:0.85, scaleY: 0.85, transformOrigin:"center"})
        .to(twoShadow.current, 0.3, {scaleX:0.45, scaleY: 0.75, transformOrigin:"center"}, 0)

        .to(twoWindow.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"center", ease:Elastic.easeOut})
        .to(twoShadow.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"center", ease:Elastic.easeOut}, "-=0.6")
        .to(twoCircle1.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"center", ease:Elastic.easeOut})
        .to(twoCircle2.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"center", ease:Elastic.easeOut}, "-=0.6")
        .to(twoCircle3.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"center", ease:Elastic.easeOut}, "-=0.6")
        .to(twoPath1.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"left", ease:Elastic.easeOut})
        .to(twoPath2.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
        .to(twoPath3.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
        .to(twoPath4.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
        .to(twoPath5.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
        .to(twoPath6.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"left", ease:Elastic.easeOut}, "-=0.6")
        .to(twoCheck.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"center", ease:Elastic.easeOut});
        
    }, [] )

    return (
        <div>
            <svg width="57px" height="98px">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-980.000000, -169.000000)">
                        <g transform="translate(981.000000, 171.000000)">
                            <g ref={twoWindow} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g transform="translate(-983.000000, -171.000000)" stroke="#4836FF" strokeWidth="2">
                                    <g transform="translate(981.000000, 171.000000)">
                                        <g transform="translate(2.000000, 0.000000)">
                                            <circle fill="#FFFFFF" cx="27" cy="6" r="5"></circle>
                                            <rect x="1" y="11" width="52" height="72" rx="3"></rect>
                                            <rect fill="#FFFFFF" x="15" y="7" width="25" height="8" rx="2"></rect>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <circle ref={twoCircle1} fill="#E4E1FF" cx="14" cy="33" r="4"></circle>
                            <path ref={twoPath1} d="M25,30 L45,30" stroke="#4836FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path ref={twoPath2} d="M25,35 L40,35" stroke="#4836FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <circle ref={twoCircle2} fill="#E4E1FF" cx="14" cy="49" r="4"></circle>
                            <path ref={twoPath3} d="M25,46 L45,46" stroke="#4836FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path ref={twoPath4} d="M25,51 L40,51" stroke="#4836FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <circle ref={twoCircle3} fill="#E4E1FF" cx="14" cy="65" r="4"></circle>
                            <path ref={twoPath5} d="M25,62 L45,62" id="penultimalineatexto" stroke="#4836FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path ref={twoPath6} d="M25,67 L40,67" id="ultimalineatexto" stroke="#4836FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <polyline ref={twoCheck} stroke="#4836FF" id="check" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="11 30.5155681 13.4844319 33 18.4844319 28"></polyline>
                            <ellipse ref={twoShadow} fill="#E4E1FF" cx="28.5" cy="92" rx="28.5" ry="4"></ellipse>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Metodologia



