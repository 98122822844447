import React, {useState} from "react"
import styled from "styled-components"
// import Img from "gatsby-image"
import { useSpring, animated } from 'react-spring'

//utils:
import useIntersect from '../../utils/useIntersect'

//styles:
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device from "../../styles/device"

//icons:
import Auditoria from './animations/auditoria';
import DigitalBranding from './animations/digitalBranding';
import Metodologia from './animations/metodologia';
import SoporteMantenimiento from './animations/soporteMantenimiento';
import Web from './animations/web';
import WebApps from './animations/webApps';


const ServiceContainer = styled.div`
    width: 33.3333%;
    padding: 0 10px;
    box-sizing: border-box;

    &:nth-child(1n + 4){
        margin-top: 100px;
    }

    @media ${device.tablet}{
        width: 50%;
        
        &:nth-child(1n + 3){
            margin-top: 80px;
        }
    }

    @media ${device.phone}{
        width: 100%;
        
        &:nth-child(1n + 2){
            margin-top: 95px;
        }
    }
`

const IconContainer = styled.div`
  margin: 0 auto 23px;
  max-height: 100px;
`

const ServiceTitle = styled.h3`
    color: ${colors.secondary};
    font-size: 23px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 26px;
`

const ServiceDescription = styled.div`
    color: ${colors.tertiary};
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: 300;
    line-height: 29px;

    p {
        margin: 20px 0 0;
    }
`

const ServiceItem = ({node}) => {
  
  const [show, setShow] = useState(false)

  const [ref, entry] = useIntersect({ threshold: 0.3 });

  //spring animations:
  const itemProps = useSpring({
    from: {
      opacity: 0,
      transform: `translateY(200px)`
    },
    to: {
      transform: show ? `translateY(0px)` : `translateY(200px)`,
      opacity: show ? 1 : 0,
    },
    onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
  });

  return (
    <ServiceContainer ref={ref}>
      <animated.div style={itemProps}>

        {show &&
            <IconContainer>
                {node.acf.icon_selector === "auditoria" && <Auditoria/>}
                {node.acf.icon_selector === "web" && <Web/>}
                {node.acf.icon_selector === "web_apps" && <WebApps/>}
                {node.acf.icon_selector === "digital_branding" && <DigitalBranding/>}
                {node.acf.icon_selector === "metodologia" && <Metodologia/>}
                {node.acf.icon_selector === "soporte_y_mantenimiento" && <SoporteMantenimiento/>}
            
            </IconContainer>
        }
        
        <ServiceTitle>{node.title}</ServiceTitle>
        <ServiceDescription dangerouslySetInnerHTML={{__html: node.content}}/>
      </animated.div>
    </ServiceContainer>
  )
}

export default ServiceItem;