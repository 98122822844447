import React, {useState, useEffect} from "react"
import styled from "styled-components"
import { useSpring, animated, useTrail } from 'react-spring'

import ServiceItem from './item'

//utils:
import useIntersect from '../../utils/useIntersect'

//styles:
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import device, {deviceSize} from "../../styles/device"

//icons:
import Auditoria from './animations/auditoria';
import DigitalBranding from './animations/digitalBranding';
import Metodologia from './animations/metodologia';
import SoporteMantenimiento from './animations/soporteMantenimiento';
import Web from './animations/web';
import WebApps from './animations/webApps';


const ServicesWhatContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 10px;

  h2 {
    color: ${colors.tertiary};
    font-family: ${fonts.poppins};
    font-weight: bold;
    font-size: 48px;
    line-height: 54px;
    margin: 0 0 100px -10px;
    padding: 0 10px;

    @media ${device.tablet}{
      width: 50%;
      margin: 0 0 80px;
    }

    @media ${device.phone} {
      font-size: 40px;
      line-height: 45px;
      margin: 0 0 100px;
    }
  }
`

const List = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 50px -10px 0;

  @media ${device.tablet} {
    margin-top: 0;
  }
`

const ServiceContainer = styled.div`
    width: 33.3333%;
    padding: 0 10px;
    box-sizing: border-box;

    &:nth-child(1n + 4){
        margin-top: 100px;
    }

    @media ${device.tablet}{
        width: 50%;
        
        &:nth-child(1n + 3){
            margin-top: 80px;
        }
    }

    @media ${device.phone}{
        width: 100%;
        
        &:nth-child(1n + 2){
            margin-top: 40px;
        }
    }
`

const IconContainer = styled.div`
  margin: 0 auto 40px;
  height: 100px;
`

const ServiceTitle = styled.h3`
    color: ${colors.secondary};
    font-size: 23px;
    font-family: ${fonts.poppins};
    font-weight: bold;
    line-height: 26px;
`

const ServiceDescription = styled.div`
    color: ${colors.tertiary};
    font-size: 16px;
    font-family: ${fonts.poppins};
    font-weight: 300;
    line-height: 29px;

    p {
        margin: 20px 0 0;
    }
`

const ServicesList = ({services, servicesListTitle}) => {
  
  const [show, setShow] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)


  useEffect( () => {

    const setWidth = () => setWindowWidth(window.innerWidth)

    if(typeof window !== 'undefined') {
      setWidth()
      window.addEventListener("resize", () => setWidth)
    }

    return () => window.removeEventListener("resize", () => setWidth)
    
  }, [])

  useEffect(() => {
    windowWidth < deviceSize.tablet ? setIsMobile(true) : setIsMobile(false)
  }, [windowWidth])

  
  //spring animations:
  const [titleRef, titleEntry] = useIntersect({ threshold: 1 });

  const length = services.length;

  const titleProps = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: show ? 1 : 0,
    },
    onRest: !show && titleEntry.isIntersecting ? setShow(true) : null
  });

  const trail = useTrail(length, {
    opacity: show ? 1 : 0,
    transform: show ? `translateY(0px)` : `translateY(200px)`,
    from: {
      opacity: 0, 
      transform: `translateY(200px)`
    }
  });

  return (
      <ServicesWhatContainer>
  
        <animated.h2 ref={titleRef} style={titleProps}>{servicesListTitle}</animated.h2>

        <List>
          { !isMobile ? trail.map((props, index) => index < length && (
            
            <ServiceContainer key={"Service-" + index}>
              <animated.div style={props}>
              
              {show &&
                <IconContainer>

                  {services[index].node.acf.icon_selector === "auditoria" && <Auditoria/>}
                  {services[index].node.acf.icon_selector === "web" && <Web/>}
                  {services[index].node.acf.icon_selector === "web_apps" && <WebApps/>}
                  {services[index].node.acf.icon_selector === "digital_branding" && <DigitalBranding/>}
                  {services[index].node.acf.icon_selector === "metodologia" && <Metodologia/>}
                  {services[index].node.acf.icon_selector === "soporte_y_mantenimiento" && <SoporteMantenimiento/>}

                </IconContainer> 
              }

                <ServiceTitle>{services[index].node.title}</ServiceTitle>
                <ServiceDescription dangerouslySetInnerHTML={{__html: services[index].node.content}}/>

              </animated.div>
            </ServiceContainer>

            )) :

            services.map(({node}, index) => (
              <ServiceItem node={node} key={"Service-" + index} />
            ))

          }
        </List>
          
  </ServicesWhatContainer>
  )
}

export default ServicesList