import React, {useRef, useEffect} from "react"
import {TimelineMax, Elastic} from 'gsap'

const randomNumber = Math.floor(Math.random() * (15 - 2 + 1) ) + 2


const SoporteMantenimiento = () => {

    const oneWindow = useRef()
    const onePath1 = useRef()
    const oneCircle = useRef()
    const onePath2 = useRef()
    const onePath3 = useRef()
    const oneShadow = useRef()

    useEffect( () => {
        const tl = new TimelineMax({repeat:-1, repeatDelay: randomNumber});
        tl.set(onePath1.current, {scaleX:0, scaleY: 0, transformOrigin:"top"})
        .set(oneCircle.current, {scaleX:0, scaleY: 0, transformOrigin:"center"})
        .set(onePath2.current, {scaleX:0, scaleY: 0, transformOrigin:"left"})
        .set(onePath3.current, {scaleX:0, scaleY: 0, transformOrigin:"right"})

        .to(oneWindow.current, 0.3, {scaleX:0.85, scaleY: 0.85, transformOrigin:"center"})
        .to(oneShadow.current, 0.3, {scaleX:0.45, scaleY: 0.75, transformOrigin:"center"}, 0)
        .to(oneWindow.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"center", ease:Elastic.easeOut})
        .to(oneShadow.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"center", ease:Elastic.easeOut}, "-=0.6")
        .to(onePath1.current, 0.6, {scaleX:1, scaleY:1, transformOrigin:"top", ease:Elastic.easeOut}, "-=0.3")
        .to(oneCircle.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"center", ease:Elastic.easeOut}, "-=0.2")
        .to(onePath2.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"left", ease:Elastic.easeOut})
        .to(onePath3.current, 0.6, {scaleX:1, scaleY: 1, transformOrigin:"right", ease:Elastic.easeOut}, "-=0.6")
        
    }, [] )

    return (
        <div className="ONE">
            <svg width="68px" height="85px">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-1139.000000, -182.000000)">
                        <g transform="translate(1140.000000, 183.000000)">
                            <g ref={oneWindow} transform="translate(-0.000000, 0.000000)" stroke="#4836FF">
                                <rect strokeWidth="2" fill="#FFFFFF" x="1" y="1" width="61.976802" height="43.8823529" rx="3"></rect>
                                <path d="M0.969345485,8.07787904 L63.0145132,8.07787904" strokeWidth="2" fill="#D8D8D8" strokeLinejoin="round"></path>
                                <circle fill="#FFFFFF" cx="4.2004971" cy="4.2004971" r="1"></circle>
                                <circle fill="#FFFFFF" cx="8.07787904" cy="4.2004971" r="1"></circle>
                                <circle fill="#FFFFFF" cx="11.955261" cy="4.2004971" r="1"></circle>
                            </g>
                            <path ref={onePath1} d="M32.1176471,56.6003161 L32.1176471,45.5102316" stroke="#4836FF" strokeWidth="2" fill="#D8D8D8" strokeLinecap="round" strokeLinejoin="round"></path>
                            <circle ref={oneCircle} stroke="#4836FF" strokeWidth="2" fill="#D8D8D8" cx="32.1176471" cy="60.4117647" r="3.58823529"></circle>
                            <path ref={onePath3} d="M0.969345485,60.4117647 L28.214926,60.4117647" stroke="#4836FF" strokeWidth="2" fill="#D8D8D8" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path ref={onePath2} d="M35.9394335,60.4117647 L63.2659486,60.4117647" stroke="#4836FF" strokeWidth="2" fill="#D8D8D8" strokeLinecap="round" strokeLinejoin="round"></path>
                            <ellipse ref={oneShadow} fill="#E4E1FF" cx="32.5" cy="79" rx="28.5" ry="4"></ellipse>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default SoporteMantenimiento